import { useEffect } from 'react'

import { selectUserCurrency } from '@redux/features/user/user.selectors'
import { setUserCurrency } from '@redux/features/user/user.slice'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { useGetCurrenciesQuery } from '@reduxCommonApp/api/endpoints'

import FlagIcon from '@base/FlagIcon'
import useLangCode from '@hooks/useLangCode/useLangCode'
import AngleDownIcon from '@iconscout/unicons/svg/line/angle-down.svg'
import { cookie } from '@services/cookies/cookies'

import { CurrencyWrapper, Wrapper } from './styles'

export const ClassicView = () => {
  const { data: currenciesList } = useGetCurrenciesQuery(true)
  const dispatch = useAppDispatch()
  const currentLangCode = useLangCode()
  const currentCurrency = useAppSelector(selectUserCurrency)

  useEffect(() => {
    //TODO ONLY FOR EXPERIMENT
    const cookieCurrency = cookie.getCookie('user_currency')

    if (cookieCurrency) {
      dispatch(setUserCurrency(cookieCurrency))
    }
  }, [])

  const currentCurrencyData = currentCurrency && currenciesList && currenciesList[currentCurrency]

  return (
    <Wrapper>
      <AngleDownIcon />
      <FlagIcon code={currentLangCode === 'en' ? 'us' : currentLangCode} height={13} width={18} />
      {!!currentCurrencyData && (
        <CurrencyWrapper>
          <span>{currentCurrencyData?.sign}</span>
          <span>{currentCurrencyData?.label}</span>
        </CurrencyWrapper>
      )}
    </Wrapper>
  )
}
